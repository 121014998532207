<template>
  <div class="reason-info-card-desktop d-flex align-items-center">
    <img :src="require(`@/assets/images/icons/ic_${icon}.svg`)" />
    <div class="info-card-content p-3">
      <p class="fs-22 lh-32 mb-0 ml-3">
        <b>{{ title }}</b>
      </p>
      <ul class="fs-14 font-weight-thin">
        <li v-for="(subtitle, index) of subtitles" :key="index">{{ subtitle }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReasonInfoCard",
	props: {
		icon: {
			type: String,
			default: () => ""
		},
		title: {
			type: String,
			default: () => ""
		},
		subtitles: {
			type: Array,
		}
	},
};
</script>
<style lang="scss">
.reason-info-card-desktop {
  height: 180px;
	width: calc(100% + 15px);
  img {
    height: inherit;
    z-index: 2;
  }
  .info-card-content {
    height: inherit;
		display: unset !important;
    transform: translateX(-15px);
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    z-index: 1;
    ul {
      width: 100%;
      margin: 0 !important;
    }
  }
}
</style>